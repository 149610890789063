import { useRouter } from "next/router";
import { useContext } from "react";
import { Routes } from "./constants";

import GlobalContext, { GlobalContextInterface } from "./context";

const useGlobalState = (): GlobalContextInterface => {
  const globalContext = useContext(GlobalContext);
  return globalContext;
};

const useLogOut = () => {
  const globalState = useGlobalState();
  const router = useRouter();
  const logout = () => {
    const globalStateCopy = { ...globalState.state };
    globalStateCopy.isAuthenticated = false;
    globalStateCopy.token = null;
    globalStateCopy.userId = null;
    globalStateCopy.ethPublicKey = null;
    localStorage.clear();

    globalState.setState(globalStateCopy);

    if (router.asPath !== Routes.Login) {
      router.push(Routes.Login);
    }
  };

  return { logout };
};

export { useGlobalState, useLogOut };
