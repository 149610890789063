import { ethers } from "ethers";
import metamaskUtils from "../components/metamaskConnect/MetamaskConnect.utils";

type BlockchainNetwork = {
  network: string;
  chainId: number;
};

type BlockChainConfig = { [key in SupportedNetworks]: BlockchainNetwork };

enum SupportedNetworks {
  Rinkeby = "rinkeby",
  Ropsten = "ropsten",
  EthereumMainnet = "ethereumMainnet",
  EthereumTestnetSepolia = "sepolia",
}

const BlockchainConfiguration: BlockChainConfig = {
  [SupportedNetworks.Rinkeby]: {
    network: "rinkeby",
    chainId: 4,
  },
  [SupportedNetworks.Ropsten]: {
    network: "ropsten",
    chainId: 3,
  },
  [SupportedNetworks.EthereumMainnet]: {
    network: "ethereum mainnet",
    chainId: 1,
  },
  [SupportedNetworks.EthereumTestnetSepolia]: {
    network: "ethereum testnet Sepolia",
    chainId: 11155111,
  },
};

export const getNetworkName = (chainId: number): string | undefined => {
  const networks = Object.values(SupportedNetworks);
  const network = networks.find(
    (network) => BlockchainConfiguration[network].chainId === chainId
  );
  if (!network) return undefined;
  return BlockchainConfiguration[network].network;
};

export const getExpectedBlockchainId = () => {
  return parseInt(process.env.NEXT_PUBLIC_BLOCKCHAIN_CHAIN_ID ?? "");
};

export const getExpectedNetworkName = () => {
  return process.env.NEXT_PUBLIC_BLOCKCHAIN_NETWORK_NAME;
};

const etherScanBaseURL = () => {
  return process.env.NEXT_PUBLIC_ETHERSCAN_BASE_URL;
};

export const getContractAddress = () => {
  return process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
};

export const getEtherScanTransactionURL = (hash: string) => {
  return etherScanBaseURL() + "/tx/" + hash;
};

export const getEtherScanContractURL = (hash: string) => {
  return etherScanBaseURL() + "/address/" + hash;
};

export const getOpenSeaTokenURL = (mintedTokenId: string) => {
  return `${
    process.env.NEXT_PUBLIC_OPENSEA_BASE_URL
  }/${getContractAddress()}/${mintedTokenId}`;
};

export const getCurrentBlockchainId = async () => {
  try {
    const provider = metamaskUtils.createMetaMaskProvider();
    const { chainId } = await provider.getNetwork();
    return chainId;
  } catch (error) {
    console.log("Error: ", error);
  }
};
