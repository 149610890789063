import { useState, ReactNode, useEffect } from "react";

import GlobalContext, {
  GlobalContextInterface,
  GlobalStateInterface,
} from "./context";
import {
  ETHEREUM_PUBLIC_KEY_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  USER_ID_STORAGE_KEY,
} from "./constants";
import { ErrorTracking } from "../error_tracking/error_tracking";

interface GlobalStateProps {
  children: ReactNode;
}

const GlobalStateProvider = ({ children }: GlobalStateProps) => {
  const isClient = typeof window !== "undefined";

  const [state, setState] = useState<GlobalStateInterface>(() => {
    if (!isClient) {
      return {
        token: null,
        userId: null,
        isAuthenticated: false,
        ethPublicKey: null,
      };
    }

    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const userId = localStorage.getItem(USER_ID_STORAGE_KEY);
    const ethPublicKey = localStorage.getItem(ETHEREUM_PUBLIC_KEY_STORAGE_KEY);

    return {
      token,
      userId,
      isAuthenticated: !!token,
      ethPublicKey,
    };
  });

  useEffect(() => {
    if (state.token && state.ethPublicKey) {
      ErrorTracking.setUser(state.ethPublicKey);
    }
  }, [state.token, state.ethPublicKey]);

  const contextValue: GlobalContextInterface = {
    state,
    setState,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStateProvider;
