import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.css";
import GlobalState from "../global/GlobalState";
import NetowrkValidator from "../networkValidator";
import { useEffect } from "react";
import { ErrorTracking } from "../error_tracking/error_tracking";
import { useRouter } from "next/router";
import { Routes } from "../global/constants";

// Routes that should not be wrapped with NetworkValidator
const EXCLUDED_ROUTES = [
  Routes.Login,
  // Add more routes here as needed, e.g.:
  // "/register",
  // "/password-reset",
];

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const shouldSkipNetworkValidator = EXCLUDED_ROUTES.some(
    (route) =>
      router.pathname === route || router.pathname.startsWith(`${route}/`)
  );

  useEffect(() => {
    ErrorTracking.initialise();
    console.log(`Running ${process.env.NEXT_PUBLIC_ENVIRONMENT} env`);
  }, []);

  return (
    <GlobalState>
      {shouldSkipNetworkValidator ? (
        <Component {...pageProps} />
      ) : (
        <NetowrkValidator>
          <Component {...pageProps} />
        </NetowrkValidator>
      )}
    </GlobalState>
  );
}

export default MyApp;
