import { createContext } from "react";

export interface GlobalStateInterface {
  token: string | null;
  userId: string | null;
  isAuthenticated: boolean;
  ethPublicKey: string | null;
}

export interface GlobalContextInterface {
  state: GlobalStateInterface;
  setState: (state: GlobalStateInterface) => void;
}

const defaultContext: GlobalContextInterface = {
  state: {
    token: null,
    userId: null,
    isAuthenticated: false,
    ethPublicKey: null,
  },
  setState: () => {},
};
const GlobalContext = createContext<GlobalContextInterface>(defaultContext);

export default GlobalContext;
