import { Contract, ethers, utils } from "ethers";
import { GenericResponse } from "../../clients/backend";
import ContractJSON from "../../contract/BlockposalGenesis.json";
import { getContractAddress } from "../../utils/blockchainConfig";
import { ErrorTracking } from "../../error_tracking/error_tracking";

const isMetamaskInstalled = (): boolean => {
  const { ethereum } = window as any;
  return Boolean(ethereum && ethereum.isMetaMask);
};

const getEthereumAccount = async (): Promise<string | undefined> => {
  const { ethereum } = window as any;
  try {
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length == 0) {
      ErrorTracking.infoBreadcrumb("No wallet selected");
      return undefined;
    }
    if (accounts.length > 1) {
      ErrorTracking.infoBreadcrumb("More than one account selected");
    }
    return accounts[0];
  } catch (error: any) {
    ErrorTracking.captureError("getEthereumAccount");

    if (error.message == "User rejected the request.") {
      ErrorTracking.infoBreadcrumb("User rejected the web3 popup");
    }
    ErrorTracking.errorBreadcrumb(`${error}`);

    return undefined;
  }
};

const connectToWallet = async (): Promise<boolean> => {
  const { ethereum } = window as any;
  try {
    await ethereum.request({
      method: "eth_requestAccounts",
      params: [{ eth_accounts: { providerType: ["metamask"] } }],
    });
    return true;
  } catch (error: any) {
    ErrorTracking.captureError("connectToWallet");
    ErrorTracking.errorBreadcrumb(`${error}`);

    return false;
  }
};

const safelyGetEthereumObject = (): any | undefined => {
  const isClient = typeof window !== "undefined" ? true : false;
  if (!isClient) {
    return undefined;
  }
  const { ethereum } = window as any;
  return ethereum;
};

const createMetaMaskProvider = () => {
  const { ethereum } = window as any;
  // Set MetaMask as the default provider
  if (ethereum && ethereum.providers) {
    const metamaskProvider = ethereum.providers.find(
      (provider: any) => provider.isMetaMask
    );
    if (metamaskProvider) {
      return new ethers.providers.Web3Provider(metamaskProvider);
    }
  }
  // Fallback to default provider
  return new ethers.providers.Web3Provider(ethereum);
};

type SignMessageData = {
  success: boolean;
  signature?: string;
  failureReason?: string;
};

const signMessage = async (
  account: string | undefined,
  message: string
): Promise<SignMessageData> => {
  if (account === undefined) {
    return { success: false, failureReason: "No account" };
  }
  const ethereum = safelyGetEthereumObject();

  try {
    const signature = await ethereum.request({
      method: "personal_sign",
      params: [message, account],
    });

    return {
      success: true,
      signature: signature,
    };
  } catch (error) {
    ErrorTracking.captureError("signMessage");
    ErrorTracking.errorBreadcrumb(`${error}`);
    return {
      success: false,
      failureReason:
        "Signature denied: please sign the signature request when trying again.",
    };
  }
};

type MetamaskTransactionData = {
  transactionHash: string;
};

const createContract = async () => {
  const { ethereum } = window as any;
  const provider = createMetaMaskProvider();
  const contractABI = ContractJSON.abi;
  const contractAddress = getContractAddress();
  if (!contractAddress) {
    throw new Error("Contract address not found");
  }

  await provider.send("eth_requestAccounts", [
    { eth_accounts: { providerType: ["metamask"] } },
  ]);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);

  return contract;
};

const getMintPrice = async (contract: Contract): Promise<string> => {
  try {
    const price = await contract.getMintingFee();
    return price.toString();
  } catch (error) {
    console.error("Error getting mint price:", error);
    throw error;
  }
};

const sendTransaction = async (
  metadataLocation: string,
  proposalHash: string
): Promise<GenericResponse<MetamaskTransactionData>> => {
  try {
    const { ethereum } = window as any;
    const provider = createMetaMaskProvider();
    const contractABI = ContractJSON.abi;
    const contractAddress = getContractAddress();

    if (!contractAddress) {
      return {
        success: false,
        error: "Contract address could not be found",
      };
    }

    await provider.send("eth_requestAccounts", [
      { eth_accounts: { providerType: ["metamask"] } },
    ]);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);
    const mintPrice = await getMintPrice(contract);

    const gasEstimate = await contract.estimateGas.publicMint(
      metadataLocation,
      proposalHash,
      { value: mintPrice }
    );

    const options = {
      gasLimit: Math.floor(Number(gasEstimate.toString()) * 1.25),
      value: mintPrice,
    };

    const transaction = await contract.publicMint(
      metadataLocation,
      proposalHash,
      options
    );

    // TODO- uncomment once backend can receive tokenId along with blockchain_transaction_id during confirm call

    // console.log({ transaction });
    // const transactionHasMined = await provider.waitForTransaction(
    //   transaction.hash
    // );
    // console.log({ transactionHasMined });

    // const receipt = await provider.getTransactionReceipt(transaction.hash);
    // console.log({ receipt });

    // const hex = receipt.logs[0].topics[3];
    // const tokenId = parseInt(hex);
    // console.log({ tokenId });

    return {
      success: true,
      payload: {
        transactionHash: transaction.hash,
      },
    };
  } catch (error: any) {
    ErrorTracking.captureError("sendTransaction");
    ErrorTracking.errorBreadcrumb(`${error}`);
    return {
      success: false,
      error:
        error?.message ??
        "There was an issue committing your proposal to the Blockchain. Check your wallet has sufficient funds for the transaction and try again.",
    };
  }
};

type TransferNFTResponse = {
  transactionHash: string;
};
const transferNFT = async (
  toAddress: string,
  fromAddress: string,
  tokenId: string
): Promise<GenericResponse<TransferNFTResponse>> => {
  const price = "0.00";
  try {
    const contract = await createContract();
    const gasEstimate = await contract.estimateGas[
      "safeTransferFrom(address,address,uint256)"
    ](fromAddress, toAddress, tokenId, { value: utils.parseEther(price) });

    const options = {
      gasLimit: Math.floor(Number(gasEstimate.toString()) * 1.25),
      value: utils.parseEther(price),
    };

    const transactionHash = await contract[
      "safeTransferFrom(address,address,uint256)"
    ](fromAddress, toAddress, tokenId, options);

    return {
      success: true,
      payload: {
        transactionHash,
      },
    };
  } catch (error: any) {
    ErrorTracking.captureError("transferNFT");
    ErrorTracking.errorBreadcrumb(`${error}`);
    return {
      success: false,
      error:
        error?.message ??
        "There was an issue committing your proposal to the Blockchain.",
    };
  }
};

const getTokenId = async (transactionHash: string) => {
  try {
    const { ethereum } = window as any;
    const provider = createMetaMaskProvider();
    const receipt = await provider.getTransactionReceipt(transactionHash);
    const tokenIdHex = receipt.logs[0].topics[3];
    const tokenId = parseInt(tokenIdHex);
    return tokenId;
  } catch (error: any) {
    ErrorTracking.captureError("getTokenId");
    ErrorTracking.errorBreadcrumb(`${error}`);
    return undefined;
  }
};

export default {
  connectToWallet,
  isMetamaskInstalled,
  getEthereumAccount,
  safelyGetEthereumObject,
  createMetaMaskProvider,
  signMessage,
  sendTransaction,
  transferNFT,
  getTokenId,
};
