import React from "react";
import styles from "./baseText.module.css";

const PRIMARY_FONT = "cairo";
const SECONDARY_FONT = "helvetica";

export enum Font {
  Primary = "primary",
  Secondary = "secondary",
}

export enum TextSize {
  Title = "title",
  Heading = "heading",
  Large = "large",
  Body = "body",
  Small = "small",
  Mini = "mini",
  SuperMini = "superMini",
}

export enum FontWeight {
  Regular = "regular",
  SemiBold = "semiBold",
  Medium = "medium",
  Bold = "bold",
  Light = "light",
}

type Props = {
  text: string;
  font?: Font;
  size?: TextSize;
  fontWeight?: FontWeight;
  className?: string;
  style?: React.CSSProperties;
  singleLine?: boolean;
};

const getFont = (font: Font) => {
  switch (font) {
    case Font.Primary:
      return styles.primaryFont;
    case Font.Secondary:
      return styles.secondaryFont;
  }
};

const getTextSize = (size: TextSize) => {
  switch (size) {
    case TextSize.Title:
      return styles.title;
    case TextSize.Heading:
      return styles.heading;
    case TextSize.Large:
      return styles.large;
    case TextSize.Body:
      return styles.body;
    case TextSize.Small:
      return styles.small;
    case TextSize.Mini:
      return styles.mini;
    case TextSize.SuperMini:
      return styles.superMini;
  }
};

const fontWeightMap = {
  [FontWeight.Bold]: "bold",
  [FontWeight.SemiBold]: 600,
  [FontWeight.Medium]: 500,
  [FontWeight.Regular]: 400,
  [FontWeight.Light]: 300,
};

// TODO refactor all text instances with component
const BaseText = ({
  text,
  font = Font.Primary,
  size = TextSize.Body,
  fontWeight = FontWeight.Regular,
  className,
  style,
  singleLine = false,
}: Props) => {
  const textSizeClass = getTextSize(size);
  const fontClass = getFont(font);
  const singleLineClass = singleLine ? styles.singleLine : [];
  const textClass = [textSizeClass, fontClass, singleLineClass];

  const joinedTextClasses = textClass.join(" ");
  const textStyle = {
    fontWeight: fontWeightMap[fontWeight],
    ...style,
  };

  return (
    <div className={[joinedTextClasses, className].join(" ")} style={textStyle}>
      {text}
    </div>
  );
};

export { BaseText };
