enum RoutesRemote {
  Login = "/login.html",
  Proposals = "/proposals.html",
  CreateProposal = "/createProposal.html",
  Proposal = "/proposal.html",
}

enum RoutesLocal {
  Login = "/login",
  Proposals = "/proposals",
  CreateProposal = "/createProposal",
  Proposal = "/proposal",
}

export const Routes = !!process.env.NEXT_PUBLIC_LOCAL
  ? RoutesLocal
  : RoutesRemote;

export const METAMASK_URL = "https://metamask.io/";
export const ETHEREUM_PUBLIC_KEY_STORAGE_KEY = "ethPublicKey";
export const TOKEN_STORAGE_KEY = "tokenKey";
export const USER_ID_STORAGE_KEY = "userIdKey";

export const BREAKPOINTS = {
  mobile: 800,
};

export const NO_OF_FILES_LIMIT = 5;
export const MEGATBYTES_TO_BYTES_RATIO = 1000000;
export const MAX_FILE_SIZE_MB = 10;

export const HOW_TO_VIEW_FILES_URL =
  "https://docs.ipfs.tech/quickstart/retrieve/#fetching-the-cid-with-an-ipfs-gateway";
